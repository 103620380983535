<template>
	<div class="container">
		<div class="abstract">
			<img src="../../assets/imgs/smart.png" alt="">
			<div class="text">
				<p class="title">城市更新</p>
				<p class="subTitle">urban renewal</p>
			</div>
		</div>
		
		<div >
			<div class="smart">
				<div class="text">
					<div class="top">
						<p class="title">智慧路灯</p>
						<p class="subTitle">Smart greenhouse</p>
					</div>
					<p class="content">智慧照明综合管理展示平台主要展示整个系统的关键信息，是照明管理的窗口，同时也是管理部门管理决策的重要依据。</p>
					<div class="line"></div>
				</div>
				<img src="../../assets/imgs/ld1.png" alt="">
				
			</div>
		</div>
		
		<div style="background: #F4F8FF;">
			<div class="introduce">
				<div class="text">
					<div class="top">
						<p class="title">路灯介绍</p>
						<p class="subTitle">street lamp</p>
					</div>
				</div>
				<img src="../../assets/imgs/ld9.png" alt="">
			</div>
		</div>
		
		<div class="software-system">
			<div class="top">
				<p class="title">软件系统</p>
				<p class="subTitle">software system</p>
			</div>
			
			<img src="../../assets/imgs/ld2.png" alt="">
		</div>
		
		<div class="system">
			<div class="top">
				<p class="title">系统管理功能</p>
				<p class="subTitle">System management function</p>
			</div>
			<div class="img-div">
				<img v-for="item of sysArr" :key="item" :src="item" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				sysArr: [
					require('@/assets/imgs/ld3.png'),
					require('@/assets/imgs/ld4.png'),
					require('@/assets/imgs/ld5.png'),
					require('@/assets/imgs/ld6.png'),
					require('@/assets/imgs/ld7.png'),
					require('@/assets/imgs/ld8.png'),
				]
			}
		},
	}
</script>

<style scoped>
.abstract {
	position: relative;
}

.abstract img {
	width: 100%;
}

.abstract .text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	text-align: center;
}

.abstract .text .title {
	font-size: 40px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
}

.abstract .text .subTitle {
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
	margin-top: 20px;
}

.smart {
	padding: 97px 133px 146px;
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
}

.top {
	display: flex;
	align-items: flex-end;
}

.top .title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin-right: 25px;
}

.top .subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	text-transform: uppercase;
}

.line {
	width: 25px;
	height: 3px;
	background: #184BAB;
	margin: 42px 0;
}

.smart .text .content {
	width: 636px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
	margin-top: 20px;
}

.software-system {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 156px 138px 136px;
}

.software-system  img {
	margin-top: 61px;
}

.system {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0 138px 117px;
}

.img-div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 41px;
}

.introduce {
	width: 1200px;
	margin: 0 auto;
	padding: 135px 138px 134px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
}

.introduce .text {
	position: relative;
	top: 20px;
}
</style>